import React from 'react';
import { Grid,  Box, } from '@mui/material';
import { motion } from 'framer-motion';

const InvestorsHome = () => {
  return (
    <Box>
    <Box width={"60%"} m={"auto"} sx={{ mt: 1 }}>
      <Grid
        container
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gridAutoRows: 'minmax(100px, 150px)',        
        }}
      >
        <Grid
        component={motion.div}
          item
          sx={{
            gridColumn: '1 / span 2',
            gridRow: '1',
            backgroundColor: 'rgba(200, 255, 255, 1)',
            textAlign: 'center',
            padding: '20px',
            fontSize: '30px',
            backgroundImage:"url(https://cdn.pixabay.com/photo/2024/02/17/17/20/chess-8579843_1280.jpg)",
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",   
          }}
          whileHover={{}}
        >
          Board of Directors
        </Grid>
        <Grid
          item
          sx={{
            gridColumn: '3',
            gridRow: '1 / span 2',
            backgroundColor: 'rgba(255, 200, 255, 0.8)',
            textAlign: 'center',
            padding: '20px',
            fontSize: '30px',
            height: '100%',
            backgroundImage:"url(https://cdn.pixabay.com/photo/2020/03/20/09/38/subway-stairs-4950141_1280.jpg)",
            // backgroundSize:"cover",
            backgroundRepeat:"no-repeat", 
            color:"whitesmoke" 
          }}
        >
          Notice
        </Grid>
        <Grid
       
          item
          sx={{
            gridColumn: '1',
            gridRow: '2 / span 2',
            backgroundColor: 'rgba(55,155, 155, 0.8)',
            textAlign: 'center',
            padding: '20px',
            fontSize: '30px',
            height: '100%', 
            backgroundImage:"url(https://cdn.pixabay.com/photo/2016/06/05/23/25/oil-in-water-1438458_960_720.jpg)",
            // backgroundSize:"cover",
            backgroundRepeat:"no-repeat", 
            
          }}
        >
          Board of Report
        </Grid>
        <Grid
          item
          sx={{
            gridColumn: '2',
            gridRow: '2',
            backgroundColor: 'rgba(100, 100, 100, 0.8)',
            textAlign: 'center',
            padding: '20px',
            fontSize: '30px',
            backgroundImage:"url(https://cdn.pixabay.com/photo/2015/03/03/10/26/software-657188_1280.jpg)",
            // backgroundSize:"cover",
            backgroundRepeat:"no-repeat",  
          }}
        >
          OverView
        </Grid>
        <Grid
          item
          sx={{
            gridColumn: '2/ span 3',
            gridRow: '3',
            backgroundColor: 'rgba(200, 200, 255, 0.8)',
            textAlign: 'center',
            padding: '20px',
            fontSize: '30px',
            backgroundImage:"url(https://media.istockphoto.com/id/1330103697/photo/close-up-of-hands-using-laptop-at-desktop-with-abstract-business-chart-hologram-on-blurry.jpg?s=2048x2048&w=is&k=20&c=mp5FVo9r23xGHWzKmOt1NNZxxc4fkOsu_agWZJ6h9d0=)",
            backgroundSize:"cover",
            backgroundRepeat:"no-repeat",  
            color:"whitesmoke"
          }}
        >
          Standalone Financial Statements
          </Grid>
      </Grid>
    </Box>
    </Box>
  );
};

export default InvestorsHome;
