export const Industries = [
    {
      id: 1,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-01-1.png",
      header: "Retail",
      content: [
        "We have modernized the way business is conducted through our range of digital solutions that combine accelerators, platforms and strategic partners. Our team is equipped to virtually take care of all customer requirements in the face of a rapidly evolving digital world.",
        "Integrated POS with inventory and accounting",
        "Improved billing with RFIDs, scanners",
        "Real-time inventory application",
        "Plus ease of promotional events, loyalty programs and multiple store location openings",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-01.png",
    },
    {
      id: 2,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-02-1.png",
      header: "Pharma",
      content: [
        "Material Requrement and Planning",
        "Purchase and Vendor Management",
        "Inventory",
        "Sales and Distribution",
        "Payroll and Accounting",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-02.png",
    },
    {
      id: 3,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-03-1.png",
      header: "Banking And Insurance",
      content: [
        " Loan Management",
        "Credit Card Evaluation and Collection Tracking",
        "Customer Relationship Management",
        "Complaint Tracking",
        "Processing Insurance Claims",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-03.png",
    },
    {
      id: 4,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-04-1.png",
      header: "Media And Entertainment",
      content: ["Dynamic News Portal Applications", "Video-on-demand Solutions"],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-04.png",
    },
    {
      id: 5,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-05-1.png",
      header: "Healthcare",
      content: [
        "Billing",
        "Diagnostics",
        "Pharmacy",
        "Vendor Management",
        "Central Store",
        "Patient Trackers",
        "e-Health Records",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-05.png",
    },
    {
      id: 6,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-06-1.png",
      header: "Energy",
      content: [
        "Loan Management",
        "eBilling",
        "Contract Management",
        "Human Resource Management",
        "Power and Distribution Site Connectivity",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-06.png",
    },
    {
      id: 7,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-07-1.png",
      header: "Logistics",
      content: [
        "Fleet Management",
        "Vendor Contract Management",
        "Financial Accounting",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-07.png",
    },
    {
      id: 8,
      img: "https://orchasp.com/wp-content/uploads/2021/07/Image-08-1.png",
      header: "Sugar",
      content: [
        "Cane Permits",
        "Cane Accounting",
        "Inventory",
        "Sales and Distribution",
        "Payroll Management",
        "Financial Accounting",
      ],
      logo: "https://orchasp.com/wp-content/uploads/2021/07/Icon-08.png",
    },
  ];