import { Box, Button, ListItem, Stack } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
const sideBarItems=['Board of Directors', 'Board of Report', 'OverView', 'Notice', "standalone Financial Statements"]

const SideNavigation = () => {
  return (
    <Stack sx={{ width: '200px' }} margin={'20px'} spacing={'9px'} mt={5} alignItems={"flex-start"}>
        {sideBarItems.map((text, index) => (
          <ListItem
            key={index}
            color="blue"
            variant='text'
            sx={{
              position: 'relative',
              padding: '10px 20px',
              ' &:after': {
                content: '""',
                position: 'absolute',
                left: 0,
                right: 0,
                height: '2px',
                backgroundColor: '#00C5DB',
                transform: 'scaleX(0)',
                transition: 'transform 0.9s ease',
              },
              
              '&:after': {
                bottom: 0,
                transformOrigin: 'left',
              },
             
              '&:hover:after': {
                transform: 'scaleX(1)',
              },
            }}
            component={Link}
            to={""}
          >
            {text}
          </ListItem>
        ))}
      </Stack>
  )
}

export default SideNavigation