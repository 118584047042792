import { Box, styled, Typography } from "@mui/material"
import { useAnimation, useInView,motion } from "framer-motion";
import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import pdf from "../../assets/home/Orchas_Annual_Report.pdf"

const InfoBox = styled(Box)(({ theme }) => ({
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1],
    borderRadius: theme.shape.borderRadius,
    height: "100%",
  }));
const AnnualReports = () =>{
    const ref=useRef()
    const inView=useInView(ref)
    const animationControl=useAnimation()
    useEffect(()=>{
      if(inView){
        animationControl.start("visible")
      }else{
        animationControl.start("initial")
      }
    })
      const slideupVariant={
        initial:{
          opacity:0,
          y:110
        },
        visible:{
          opacity:1,
          y:0,
          transition:{ 
            duration:2
          }      
        }
      }
     return(
      <motion.div ref={ref} variants={slideupVariant} initial="initial" animate={animationControl} >
    <InfoBox >
      <Typography variant="h6" gutterBottom>
        Annual Reports
      </Typography>
      <Box component={"a"} href={pdf} target="_blank" sx={{textDecoration:"none", color:"inherit"}}>
      <img
        src={"https://orchasp.com/wp-content/uploads/2024/09/annual_meet2023-2024.jpeg"}
        alt="Annual Report"
        style={{ width: "100%", height: "400px" }}
      />
      <Typography variant="body2" align="center" sx={{ backgroundColor:"#0BB7E5",padding:1}}>
        2023-2024
      </Typography>
      </Box>
    </InfoBox>
     </motion.div>
   
  );
  }
export default AnnualReports  