import { Box } from '@mui/material'
import React from 'react'
import SideNavigation from './SideNavigation';
import { Route, Routes } from 'react-router-dom';
import InvestorsHome from './InvestorsHome';


const DigiInvestors = () => {
  return (
  
   
    <Box className="digi-app">
      <Box className="side-nav">
        <SideNavigation />
        </Box> 
      <Box className="digi-content">
        <Routes> 
          <Route path='' element={<InvestorsHome />} />
        </Routes> 
      </Box>
    </Box>
    
  )
}

export default DigiInvestors